.StepNavigation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;
}

.StepNavigation > * {
  align-self: center;
  text-align: center;
  margin: 0.5rem !important;
}

@media (min-width: 569px) {
  .StepNavigation {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .StepNavigation.LastStep {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .StepNavigation > span:has(> .NavigationWarning) {
    order: 2;
  }

  .StepNavigation > button:last-child {
    order: 3;
  }
}

@media print {
  .StepNavigation {
    display: none;
  }
}
