.NavigationButtons {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.Fixed {
  background-color: var(--color-primary-background, #ecf6fd);
  border: 1px solid var(--color-border, #d6e2ef);
  bottom: 0;
  left: 0;
  padding: 1rem 2rem;
  position: sticky;
}

@media print {
  .NavigationButtons {
    display: none;
  }
}
