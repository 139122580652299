.Footer {
  border-top: 1px solid grey;
  font-size: 0.75rem;
  margin-top: 1rem;
  padding: 1rem;
  text-align: center;
}

.Footer > div {
  margin: 0 !important;
  padding-bottom: 0.75rem;
}

@media print {
  .Footer {
    display: inline-block;
    width: 100%;
    padding: 0.5rem;
  }
}
