.radio-card-group-last-card > section > div > div:last-child {
  min-width: 100%;
  height: 60px;  
  padding: 0px 8px!important;
}

.radio-card-group-last-card > section > div > div:last-child > label {
  flex-direction: row;
  justify-content: center;
}
.radio-card-group-last-card > section > div > div:last-child > label > div > p {
  color: var(--palette-primary-600);
}

.radio-card-group-display-card> section > div > div {
  min-width: 100%;
}

.gerudo-radio-card-checked-disabled {
  border: 3px solid var(--palette-gray-500)!important;
}
