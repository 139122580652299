.Navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;
}

.Navigation > * {
  align-self: center;
  text-align: center;
  margin: 0.5rem;
}

.Navigation > *:not(.NavigationWarning) {
  order: 1;
}

.Navigation > .NavigationWarning {
  order: 99;
}

@media (min-width: 568px) {
  .Navigation {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .Navigation > .NavigationWarning {
    order: 1;
  }
}

@media print {
  .Navigation {
    display: none;
  }
}
