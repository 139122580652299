.RadioGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  position: initial !important;
  gap: .5rem;
}

.Radio {
  margin: 0.25rem 0 !important;
}

.Radio > [class*="ant-radio"] {
  align-self: flex-start;
}

.Radio > span::after {
  display: none;
}

.GerudoRadio {
}

.GerudoRadioButton {
  justify-content: center;
}
