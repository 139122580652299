.Message {
  --animation-duration: 0s;
  margin: 0;

  -webkit-animation: display var(--animation-duration); /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: display var(--animation-duration); /* Firefox < 16 */
  -o-animation: display var(--animation-duration); /* Opera < 12.1 */
  animation: display var(--animation-duration);
}
.Message.WithDelay {
  --animation-duration: 5s;
}

@keyframes display {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes display {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes display {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes display {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes display {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
