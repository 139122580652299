.BodyPartPicker,
.ButtonGroup {
}

@media print {
  .BodyPartPicker {
    break-inside: avoid;
    max-height: 6.5in;
    max-width: 7in;
    margin: auto;
  }

  .ButtonGroup {
    display: none;
  }
}
