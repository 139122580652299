body,
html {
  height: auto !important;
}

body {
  position: relative;
  background-color: inherit !important;
  margin: 0;
  padding-bottom: 1rem;
  font-family: var(--font-family-secondary, --font-family), -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media print {
  script,
  style {
    display: none;
  }
}
