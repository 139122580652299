.PanelMenu {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.PanelMenu > button[role="switch"] {
  width: 150px;
}

.PanelMenu > *:not(button[role="switch"]) {
  flex-grow: 1;
  flex-shrink: 0;
  background-color: #eee;
  border: 1px solid #1890ff;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}

.PanelMenu > :not(:first-child) {
  margin-left: 4px;
}

.Button:not([disabled]) {
  cursor: pointer;
}

.Button:not([disabled]):hover {
  font-weight: bold;
}

.Button[disabled] {
  cursor: not-allowed;
  opacity: 0.4;
}
