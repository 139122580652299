.Reset .ResetIcon {
  margin: 0 0.65rem 0.15rem 0.15rem;
  font-size: 18px;
  vertical-align: middle;
}
.Reset .ResetIcon > svg {
  transform: scaleX(-1) rotate(90deg);
}

@media print {
  .Reset {
    display: none;
  }
}
