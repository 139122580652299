.ItemWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}

.Prefix,
.Suffix {
  font-weight: bolder;
  margin: 0.5rem 0.75rem;
}

.Prefix {
  margin-left: 0;
}

.Suffix {
  margin-right: 0;
}
