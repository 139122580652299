.Label {
  font-size: var(--font-size-input, 16px);
  font-weight: var(--font-weight-input, 300);
  margin: 0;
  white-space: nowrap;
}


.Inherit {
  font-size: inherit;
  font-weight: inherit;
}
