.Item {
  position: relative;
  padding-bottom: 0.5rem;
}

.BorderBetweenProperties > :not(:first-child) {
  border-top: 1px solid var(--color-border, #d6e2ef);
}

.CanBeDeleted {
  padding-top: 5rem !important;
}

.CanBeDeleted.EmbededDeleteButton {
  padding-top: 2rem !important;
}

.AddItem.EmbedBordered {
  border-top: 1px solid var(--color-border, #d6e2ef);
  margin: 1rem -0.75rem 0;
  padding: 1rem 0.75rem 0;
}
@media (min-width: 568px) {
  .AddItem.EmbedBordered {
    margin: 1rem -2rem 0;
    padding: 1.5rem 2rem 0;
  }
}

.DeleteItem {
  border: none !important;
  background-color: transparent;
  color: var(--palette-gray-600);
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  position: absolute !important; 
  right: 0;
  top: 1.5rem;
  z-index: 999;

  &[disabled] {
    color: var(--palette-gray-300);
    cursor: not-allowed;
  }
}

.DeleteItem.HighlightBordered {
  right: 1.5rem;
}

.RemoveButtonLabel {
  padding-left: 0.5rem;
}

.NoItem {
  background: var(--grey-extra-light-97);
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  margin: 1rem 0 1.5rem 0;
  padding: 1rem 0;
}


@media print {
  .AddItem,
  .DeleteItem {
    display: none;
  }
}
