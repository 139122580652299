.CheckboxButton {
  font-size: 14px;
}

.CheckboxGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  position: initial !important;
  gap: .5rem;
}

.Checkbox {
  margin: 0.25rem 0 !important;
}

.Checkbox > [class*="ant-checkbox"] {
  align-self: flex-start;
}
