.QV {
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
}

.QV > thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;
}

.QV input,
.QV select {
  width: 100%;
  height: 100%;
}

.QV td,
.QV th {
  border: 1px solid #ddd;
  padding: 2px 4px;
}

.QV th label {
  margin-right: 0.5rem;
}
