.LoadingBar {
  min-width: 100%;
  z-index: 999;

  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}

@keyframes fadein {
  0%,
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  0%,
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  0%,
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  0%,
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  0%,
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
