.StepLayout {
  padding: 0;
}

.OutroIcon svg {
  fill: #388b4b;
  margin-right: 1rem;
}

@media (min-width: 568px) {
  .StepLayout {
    padding: 0 0.5rem;
  }
}

@media (min-width: 768px) {
  .StepLayout {
    padding: 0 1rem;
  }
}
