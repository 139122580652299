.Viewer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: stretch;
  padding: 0 8px 8px 8px;
}

.Viewer > div:first-child {
  flex: 1;
}

.Loading * {
  cursor: wait !important;
}

.LoadingBar {
  margin-top: -0.6rem;
  position: fixed;
  left: 0;
  top: 0;
}

.LoadingBar * {
  border-radius: 0;
}

.DevPanelWrapper {
  background: rgba(238, 238, 238, 1);
  min-height: 100vh;
  width: 750px;
}

@media print {
  .DevPanelWrapper {
    display: none;
  }
}
