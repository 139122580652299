.SectionalFormRenderer {
  margin: 0 !important;
  display: flex;
}

.Stepper {
  margin-bottom: 0;
}

.Layout {
  flex: 1;
  overflow: auto;
}

@media (max-width: 900px) {
  .SectionalFormRenderer {
    flex-direction: column;
  }
}

@media (min-width: 901px) {
  .SectionalFormRenderer {
    gap: var(--padding-huge, 32px);
  }

  .Stepper {
    min-width: 380px;
    max-width: 380px;
    margin-bottom: 1rem;
  }

  .Layout {
    margin-right: var(--padding-huge, 32px);
  }
}

@media print {
  .Stepper {
    display: none;
    margin-bottom: 0;
  }
  .Layout {
    margin-right: 0;
  }
}
