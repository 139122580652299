.NavigationWarning {
  align-self: center;
  padding: 0 1rem;
  width: 100%;
}

@media (min-width: 568px) {
  .NavigationWarning {
    width: auto;
  }
}
