.PropertyWrapper {
  --animation-duration: 500ms;
  --border-style: 1px solid var(--color-border, #d6e2ef);

  --property-margin-top: 0;
  --property-margin-right: 0;
  --property-margin-bottom: 0;
  --property-margin-left: 0;
  --property-padding-top: 1rem;
  --property-padding-bottom: 1rem;

  /* --property-margin-bottom: 1rem + 1.85rem; to leave enough space for the reinit button */
  --reset-space: 2.85rem;

  position: relative;
}

.PropertyWrapper.TopBorder {
  border-top: var(--border-style);
}
.PropertyWrapper.RightBorder {
  border-right: var(--border-style);
  padding-right: 1.25rem;
}
.PropertyWrapper.BottomBorder {
  border-bottom: var(--border-style);
}
.PropertyWrapper.LeftBorder {
  border-left: var(--border-style);
  padding-left: 1.25rem;
}

.Property {
  flex: 1 0 100%;
  margin: var(--property-margin-top) var(--property-margin-right) var(--property-margin-bottom)
    var(--property-margin-left);
  padding: var(--property-padding-top) 0 var(--property-padding-bottom) 0;
  position: relative;

  a[target="_blank"]:not(.gerudo-link)::after {
    content: url("../../../public/external-link.svg");
    display: inline-block;
    margin: 0 0.5ch;
    height: 0.75em;
    width: 0.75em;
  }
}

fieldset.Property > * {
  clear: both;
}

.Property [role="alert"] {
  margin: 0.5rem 0;
}

.Property.Bordered {
  --property-margin-bottom: 1rem;
  background-color: var(--color-primary-background, #ecf6fd);
  border-radius: 0.5rem;
  padding: 0.75rem;
  min-width: 100%;
}
.Property.Bordered.Valid {
  background-color: #fff;
}

.Property.Bordered.HighlightInvalid {
  border: 2px solid var(--palette-warning-400, #faad14);
}

.Property.Bordered,
.Property.Bordered.CanReset + .ResetLink {
  border: var(--border-style);
}
.Property.Bordered.CanReset {
  margin-bottom: var(--reset-space);

  -webkit-animation: add-margin-bottom var(--animation-duration); /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: add-margin-bottom var(--animation-duration); /* Firefox < 16 */
  -o-animation: add-margin-bottom var(--animation-duration); /* Opera < 12.1 */
  animation: add-margin-bottom var(--animation-duration);
}
.Property.Bordered.CanReset + .ResetLink {
  --height-reset-link: 2em;
  --hide-reset-bottom-value: 3rem;
  --show-reset-bottom-value: calc(var(--reset-space) - calc(var(--height-reset-link) - 1px));

  border-radius: 0 0 0.5rem 0.5rem;
  bottom: var(--show-reset-bottom-value);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: var(--height-reset-link);
  padding: 0 1rem;
  position: absolute;
  right: 2rem;
  z-index: 0;
  background-color: #ffffff;

  -webkit-animation: show-reset var(--animation-duration); /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: show-reset var(--animation-duration); /* Firefox < 16 */
  -o-animation: show-reset var(--animation-duration); /* Opera < 12.1 */
  animation: show-reset var(--animation-duration);
}
@media (min-width: 568px) {
  .Property.Bordered {
    padding: 1rem 2rem;
    min-width: auto;
  }
}

.Property.CanReset:not(.Bordered) {
  padding-bottom: 0;
}
.Property.CanReset:not(.Bordered) + .ResetLink {
  margin-bottom: 1.5rem;
  z-index: -1;
}
.PropertyWrapper.Answerable > .Property.CanReset:not(.Bordered) + .ResetLink {
  margin-top: 1rem;
}

.Property:not(.EmbedBordered) {
  max-width: 100%;
}
.Property.EmbedBordered {
  --property-margin-top: 1rem;
  --property-margin-right: -0.75rem;
  --property-margin-bottom: 0;
  --property-margin-left: -0.75rem;

  border-top: var(--border-style);
  padding: 1rem 0.75rem 0;
}
@media (min-width: 568px) {
  .Property.EmbedBordered {
    --property-margin-top: 1rem;
    --property-margin-right: -2rem;
    --property-margin-bottom: 0;
    --property-margin-left: -2rem;

    padding: 1rem 2rem 0;
  }
}

.Property.HtmlElement,
.Property.Unboxed {
  --property-margin-top: 0;
  --property-margin-right: 0;
  --property-margin-bottom: 0;
  --property-margin-left: 0;

  padding: 0;
}

.Property.UnbreakablePrint {
  break-inside: inherit;
}

.TitleWrapper {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: var(--padding-small, 8px);
  gap: 0.25rem 0.5rem;
}

legend.TitleWrapper {
  /*  Prevents the legend to position itself on the top border of the fieldset as it does by default */
  float: left;
}

.DividedTitleWrapper {
  border-bottom: var(--border-style);
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
}


.TitleWrapper.WithOptionalFlag {
  justify-content: space-between;
}

.TitleWrapper.WithOptionalFlag .Optional {
  color: #737373;
  font-size: 1rem;
}


.TitleWrapper.WithRequiredFlag .Titles > * {
  display: inline;
}

.TitleWrapper.WithRequiredFlag .Required {
  color: red;
  font-size: 1rem;
}

.TitleWrapper.WithRequiredFlag .Required::before {
  /* double &nbsp; */
  content: '\0000a0\0000a0'
}



.SurroundingArea {
  margin: 0.5rem 0 !important;
}

@media print {
  .Property {
    padding: 0 !important;
    width: 100%;
  }

  .Property.Bordered,
  .Property.EmbedBordered {
    background-color: inherit;
    border: none;
  }

  .Property.Bordered.CanReset {
    --property-margin-bottom: 1rem;
  }

  .Property.UnbreakablePrint {
    break-inside: avoid;
    display: inline-block;
    width: 100%;
  }
}

@keyframes show-reset {
  from {
    display: none;
    bottom: var(--hide-reset-bottom-value);
  }
  to {
    display: block;
    bottom: var(--show-reset-bottom-value);
  }
}
@keyframes add-margin-bottom {
  from {
    margin-bottom: var(--property-margin-bottom);
  }
  to {
    margin-bottom: var(--reset-space);
  }
}

/* Firefox < 16 */
@-moz-keyframes show-reset {
  from {
    display: none;
    bottom: var(--hide-reset-bottom-value);
  }
  to {
    display: block;
    bottom: var(--show-reset-bottom-value);
  }
}
@-moz-keyframes add-margin-bottom {
  from {
    margin-bottom: var(--property-margin-bottom);
  }
  to {
    margin-bottom: var(--reset-space);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes show-reset {
  from {
    display: none;
    bottom: var(--hide-reset-bottom-value);
  }
  to {
    display: block;
    bottom: var(--show-reset-bottom-value);
  }
}
@-webkit-keyframes add-margin-bottom {
  from {
    margin-bottom: var(--property-margin-bottom);
  }
  to {
    margin-bottom: var(--reset-space);
  }
}

/* Internet Explorer */
@-ms-keyframes show-reset {
  from {
    display: none;
    bottom: var(--hide-reset-bottom-value);
  }
  to {
    display: block;
    bottom: var(--show-reset-bottom-value);
  }
}
@-ms-keyframes add-margin-bottom {
  from {
    margin-bottom: var(--property-margin-bottom);
  }
  to {
    margin-bottom: var(--reset-space);
  }
}

/* Opera < 12.1 */
@-o-keyframes show-reset {
  from {
    display: none;
    bottom: var(--hide-reset-bottom-value);
  }
  to {
    display: block;
    bottom: var(--show-reset-bottom-value);
  }
}
@-o-keyframes add-margin-bottom {
  from {
    margin-bottom: var(--property-margin-bottom);
  }
  to {
    margin-bottom: var(--reset-space);
  }
}
