.switch-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.spaced {
  justify-content: space-between;
}

.switch {
  margin-right: 1.65em;
}
