.modal {
  .gerudo-modal-body {
    padding: 0;
  }
  .header {
    background-color: var(--modal-header-background-color);
  }

  .header-notification {
    /* override because default modal header theme was not made for this */
    background-color: white !important;
  }

  .footer {
    box-shadow: var(--modal-new-footer-box-shadow);
  }

  .footer-notification {
    /* override because default modal footer theme was not made for this */
    box-shadow: none !important;
  }

  .html {
    padding: var(--modal-body-padding);
  }
}
