.Properties {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  position: initial !important;
}

@media print {
  .Properties {
    display: inline;
    width: 100%;
  }
}
