.TableContent {
  border: 1px solid var(--color-border, #d6e2ef);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}

.TableHeader,
.TableRow {
  align-self: center;
  width: 100%;
}

.TableHeader > *,
.TableRow {
  padding: 0.5rem;
}
.TableHeader {
  padding: 0 0.5rem;
}

.TableHeader *,
.TableRow * {
  align-self: center;
}

.TableHeader {
  background: #ecf6fd;
  border-bottom: 1px solid var(--color-border, #d6e2ef);
  font-weight: bold;
  text-align: center;
}

.TableHeader > :not(:first-child) {
  border-left: 1px solid var(--color-border, #d6e2ef);
}

.TableRow:not(:first-child) {
  border-top: 1px solid var(--color-border, #d6e2ef);
}

.TableRow:nth-child(even) {
  background: var(--color-app-bg-secondary, #f7f7f7);
}

@media (min-width: 568px) {
  .TableHeader > *,
  .TableRow {
    padding: 0.85rem;
  }
  .TableHeader {
    padding: 0 0.85rem;
  }
}

@media (min-width: 768px) {
  .TableHeader > *,
  .TableRow {
    padding: 1rem;
  }
  .TableHeader {
    padding: 0 1rem;
  }
}

@media print {
  .TableContent,
  .TableHeader,
  .TableRow {
    background-color: inherit;
  }
}
