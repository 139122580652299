.HtmlWrapper {
  white-space: nowrap;
}

.Html {
  white-space: normal;
}

.OverflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
